.login_main {
  width: 100%;
  overflow: hidden;

  .container {
    max-width: 1240px !important;
  }

  .box_1 {
    width: 100%;
    height: 612px;
    background: #FFFFFF;

    h2 {
      font-weight: 500;
      font-size: 48px;
      margin-bottom: 10px;
      color: #008100;
    }

    h4{
      color: #008100;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    p {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      text-align: justify;
      margin-bottom: 20px;
      color: #050505;
    }
    .bt-login__link {
      text-decoration: none;

    }
    .bt-login {
      width: 254.18px;
      height: 127.525px;
      flex-shrink: 0;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.10));
      border-radius: 10px;
      border: 1px solid var(--Main, #008100);
      background: #F6F6F6;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10);
      display: inline-flex;
      padding: 12px 22px;
      flex-direction: column;
      align-items: center;
      &:hover {
        background: white;
      }
      .bt-login__sub {
        color: var(--active-cta, #161616);
        text-align: justify;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }

    .bt-register button {
      border: 1px solid #008100;
      color: #008100;
      background: transparent;
    }
  }

  ul li {
    font-weight: 600;
    font-size: 16px;
    text-align: justify;
    color: #050505;
    margin-bottom: 6px;
  }

  .box_2 {
    width: 100%;
    height: 760px;
    background-size: 100%;
    background-repeat: no-repeat;

    h2 {
      font-weight: 500;
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 42px;
      color: #050505;
    }
  }

  .box_3 {
    width: 100%;
    height: 100%;
    padding: 66px 0;
    background: white;

    h2 {
      font-weight: 500;
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 19px;
      color: #050505;
    }
  }

  .box_4 {
    width: 100%;
    height: 100%;
    padding: 66px 0 70px;
    background: white;

    h2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      letter-spacing: 0.023em;
      margin-bottom: 28px;
      color: #2C2762;
    }

    p {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.023em;
      margin-bottom: 16px;
      color: #008100;
    }

    span {
      font-weight: 400;
      font-size: 21.33px;
      line-height: 25px;
      letter-spacing: 0.023em;
      color: #7C7C7C;

    }

    .logo {
      margin: 34px 0 30px;
    }


    .text {
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.023em;
      color: #000000;
    }
  }

  .box_6 {
    width: 100%;
    height: 100%;
    padding: 122px 0 84px;
    position: relative;
    background: linear-gradient(96.01deg, #008100 8.88%, #3DA551 87.06%);

    .img_left {
      top: 10px;
      left: 60px;
    }

    .banner_6 {
      width: 660px;
      height: 500px;
      position: relative;
      background-size: 100%;
      background-repeat: no-repeat;

      img {
        width: 180px;
      }

      .app_ios {
        left: 135px;
        top: 256px;
      }

      .app_android {
        right: 135px;
        top: 256px;
      }

    }
  }

  .footer_om {
    .footer_content {
      padding: 44px 0 0;

      .content_left {
        width: 342px;

        .logo {
          margin-bottom: 14px;
        }

        .email {
          text-decoration: underline;
        }

        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          color: #161616;
          margin-bottom: 10px;

          span, a {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #161616;
          }

        }
      }

      .list_box {
        width: 798px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .box {
          width: 186px;

          h4 {
            font-weight: 700;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.1px;
            margin-bottom: 15px;
            color: #339901;

          }

          .content {
            width: 100%;

            a {
              text-decoration: none;
              width: 100%;
              display: inline-block;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              margin-bottom: 8px;
              color: #1D1D1F;
              &:hover {
                color: #339901;
              }
            }

          }
        }
      }
    }

    .footer_right {
      width: 100%;
      height: 51px;
      background: #757575;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }
    }
  }
}


@media screen and (max-width: 414px) {
  .login_main__btn--redirect {
    justify-content: space-between;
    .bt-login__link {
      width: 50% !important;
      margin: unset !important;
    }
  }
  .login_main {
    .box_1{
      .bt-login {
        width: 90%;
        height: 87.525px;
        flex-shrink: 0;
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.10));
        border-radius: 10px;
        border: 1px solid var(--Main, #008100);
        background: #F6F6F6;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10);
        display: inline-flex;
        padding: 12px 22px;
        flex-direction: column;
        align-items: center;
        img {
          width: 100%;
        }
        &:hover {
          background: white;
        }
        .bt-login__sub {
          color: var(--active-cta, #161616);
          text-align: justify;
          font-size: 12px;
          font-style: italic;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
      }
    }
  }
  .login_main {
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }

    .box_1 {
      height: 410px;
      padding: 46px 0 15px;

      h2 {
        font-size: 32px;
        margin-bottom: 16px;
      }

      button {
        width: 170px;
      }
    }

    ul li {
      font-size: 14px;
    }

    .box_2 {
      height: 320px;

      .col-12 {
        padding: 0 !important;

        .pl-5 {
          padding-left: 15px !important;

          h2 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 12px;
          }
        }

      }
    }

    .box_3 {
      padding: 24px 0;

      .row {
        flex-direction: column-reverse;
      }

      .col-12 {
        padding: 0 !important;

        .pr-4 {
          padding-left: 15px !important;

          h2 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 12px;
          }
        }

        img {
          width: 80%;
          margin-bottom: 48px;
        }
      }
    }

    .box_4 {
      padding: 0;

      h2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
      }

      img {
        width: 85%;
      }

      .logo {
        justify-content: space-between !important;

        img {
          width: 140px !important;
        }
      }

      span, .text {
        font-size: 14px;
      }
    }

    .box_5 {
      margin-top: 48px;

      img {
        height: 100% !important;
      }
    }

    .box_6 {
      padding: 46px 0;

      .row {
        flex-direction: column-reverse;
      }

      .col-12 {
        img {
          width: 35%;
          margin: 0 !important;
        }

        .banner_6 {
          width: 100%;
          height: 310px;

          img {
            width: 110px;
          }

          .app_ios {
            left: 55px;
            top: 150px;
          }

          .app_android {
            right: 55px;
            top: 150px;
          }
        }
      }
    }

    .footer_om {
      .footer_content {
        padding: 24px 0 0;

        .content_left {
          width: 100%;

          .logo {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
          }

          p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 8px;

            span, a {
              font-size: 14px;
            }

          }
        }

        .list_box .box {
          width: 50%;

          h4 {
            font-size: 14px;
            margin-bottom: 12px;
          }

          .content {

            a {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .login_main {
    .box_6 {
      .col-12 {
        .banner_6 {
          .app_ios {
            top: 140px;
          }

          .app_android {
            top: 140px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .login_main {
    .box_1 {
      button {
        width: 165px;
      }
    }

    .box_6 {
      .col-12 {
        .banner_6 {
          img {
            width: 100px;
          }

          .app_ios {
            top: 132px;
          }

          .app_android {
            top: 132px;
          }
        }
      }
    }
  }
}
